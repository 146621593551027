import { getBrowserLang } from '@ngneat/transloco';

import { AvailableCountry } from './country';

export enum AvailableLang {
  fr = 'fr',
  en = 'en',
  nl = 'nl',
  es = 'es',
}

export const AVAILABLE_LANGS: Record<AvailableCountry, AvailableLang[]> = {
  [AvailableCountry.fra]: [AvailableLang.en, AvailableLang.fr],
  [AvailableCountry.bel]: [AvailableLang.en, AvailableLang.fr, AvailableLang.nl],
  [AvailableCountry.asia]: [AvailableLang.en],
  [AvailableCountry.spa]: [AvailableLang.en, AvailableLang.es],
  [AvailableCountry.ch]: [AvailableLang.en, AvailableLang.fr],
  [AvailableCountry.lux]: [AvailableLang.en, AvailableLang.fr, AvailableLang.nl],
};

export function getDefaultLang(): AvailableLang {
  const browserLang = Object.values(AvailableLang).find(lang => lang === getBrowserLang());
  return browserLang ? browserLang : AvailableLang.en;
}
